import InstagramStories from "../pages/Social/InstagramStories";
import YouTubeCommunity from "../pages/Social/YouTubeCommunity";
import Twitter from "../pages/Social/Twitter";
import Facebook from "../pages/Social/Facebook";
import LinkedIn from "../pages/Social/LinkedIn";
import BlogFeatured from "../pages/Social/BlogFeatured";
import BlogSocialShare from "../pages/Social/BlogSocialShare";
export const templates =
  {
    social: [
      {
        id: "Twitter",
        label: "Twitter",
        dimensions: "1200x675",
        getComponent() {
          return <Twitter id={this.id}/>
        },
      },
      {
        id: "Facebook",
        label: "Facebook",
        dimensions: "1200x675",
        getComponent() {
          return <Facebook id={this.id}/>
        },
      },
      {
        id: "LinkedIn",
        label: "LinkedIn",
        dimensions: "1200x627",
        getComponent() {
          return <LinkedIn id={this.id}/>
        },
      },

      {
        id: "YouTubeCommunity",
        label: "YouTube Community",
        dimensions: "1080x1080",
        getComponent() { return <YouTubeCommunity id={this.id}/>},
      },

      {
        id: "InstagramStories",
        label: "Instagram Stories",
        dimensions: "1080x1920",
        getComponent() {
          return <InstagramStories id={this.id}/>
        },
      },

      {
        id: "BlogFeatured",
        label: "Blog Featured",
        dimensions: "1280x720@2x",
        getComponent() { return <BlogFeatured id={this.id}/>},
      },
      {
        id: "BlogSocialShare",
        label: "Blog Social Share",
        dimensions: "1280x720@2x",
        getComponent() { return <BlogSocialShare id={this.id}/>},
      },
    ],
    livestream: [
      {
        id: "Twitter",
        label: "Twitter",
        dimensions: "1200x675",
        getComponent() {
          return <Twitter id={this.id}/>
        },
      },
      {
        id: "Facebook",
        label: "Facebook",
        dimensions: "1200x675",
        getComponent() {
          return <Facebook id={this.id}/>
        },
      },
    ],
    internal: [
      {
        id: "Twitter",
        label: "Twitter",
        dimensions: "1200x675",
        getComponent() {
          return <Twitter id={this.id}/>
        },
      },
      {
        id: "Facebook",
        label: "Facebook",
        dimensions: "1200x675",
        getComponent() {
          return <Facebook id={this.id}/>
        },
      },
    ],
    email: [
      {
        id: "Twitter",
        label: "Twitter",
        dimensions: "1200x675",
        getComponent() {
          return <Twitter id={this.id}/>
        },
      },
      {
        id: "Facebook",
        label: "Facebook",
        dimensions: "1200x675",
        getComponent() {
          return <Facebook id={this.id}/>
        },
      },
    ],
  }
