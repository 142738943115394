import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import UndoIcon from "../UndoIcon";
import {useEffect, useState, useRef} from "react";
import {useImages} from "../../contexts/images";
import './index.css';

const BgTransformer = ({ src, id }) => {
  const setTransformRef = useRef(null);
  const {bgX, editBgX, bgY, editBgY, bgScale, editBgScale, renderKey} = useImages();


  // set background position when loading a saved scene
  useEffect(() => {
    if (setTransformRef.current) {
      if (bgX[id]) {
        setTransformRef.current(bgX[id], bgY[id], bgScale[id]);
      } else {
        setTransformRef.current(0, 0, 1);
      }
    }
  }, [renderKey]);



  const handleTransform = value => {
    editBgScale(id, value.state.scale);
    editBgX(id, value.state.positionX);
    editBgY(id, value.state.positionY);
    // console.log("modified transform:", id, value.state)
  }

  return (
    <div className="bg-transformer">
      <TransformWrapper
        initialScale={bgScale[id]}
        minScale={1}
        initialPositionX={bgX[id]}
        initialPositionY={bgY[id]}
        wheel={{ step: 0.03 }}
        centerZoomedOut={true}
        centerOnInit={false}
        onTransformed={handleTransform}
      >
        {({ zoomIn, zoomOut, resetTransform, setTransform, ...rest }) => {
          setTransformRef.current = setTransform;

          return (
            <>
              <TransformComponent wrapperClass="image-wrapper">
                <img src={src} alt="background" />
              </TransformComponent>

              <div className="tools">
                <button className="round-btn" onClick={() => zoomIn()}>+</button>
                <button className="round-btn" onClick={() => zoomOut()}>−</button>
                <button className="round-btn" onClick={() => resetTransform()}>
                  <UndoIcon />
                </button>
              </div>
            </>
          );
        }}
      </TransformWrapper>
    </div>
  )}

export default BgTransformer;