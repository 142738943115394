import { useParams } from "react-router-dom";
import Social from '../pages/Social/index.js';
import Livestream from "../pages/Livestream";
import Internal from "../pages/Internal";
import Email from "../pages/Email";



const TemplateRoute = () => {
  const params = useParams();

    const templates = () => {
        switch(params.template) {
          case "social":        return <Social />;
          case "livestream":        return <Livestream />;
          case "internal":        return <Internal />;
          case "email":        return <Email />;

          default:      return <h1>Nothing's here</h1>
        }
    }
    return (
        <>
            { templates() }
        </>
    );
}

export default TemplateRoute;