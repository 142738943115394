import React, {useEffect} from "react";
import {useSetting} from "../../contexts/setting";
import {EYE_FALSE, EYE_TRUE, LOCK_FALSE, LOCK_TRUE} from "../../data/constants";
import {templates} from "../../data/templates";
import cn from "classnames";
import './index.css';


const Item = ({ label, id, isVisible, isLocked }) => {
  const {editVisible, editLocked} = useSetting();
  // console.log(isVisible, isLocked)
  const lockClass = cn('lock checkbox', { "locked": isLocked });
  const visibleClass = cn('eye checkbox', { "visible": isVisible });
  return (
  <div className="template-option">
    <input
      className="template-checkbox"
      type="checkbox"
      id={`visibility-${id}`}
      checked={isVisible}
      onChange={() => editVisible(id, !isVisible)}
      />
    <label htmlFor={`visibility-${id}`} className={visibleClass}>
      {isVisible ? <img src={EYE_TRUE} alt=""/> : <img src={EYE_FALSE} alt=""/>}
    </label>
    <div className="template-label">{label}</div>
    <input
      className="template-checkbox"
      type="checkbox"
      id={`lock-${id}`}
      checked={isLocked}
      onChange={() => editLocked(id, !isLocked)}
      />
    <label htmlFor={`lock-${id}`} className={lockClass}>
      {isLocked ? <img src={LOCK_TRUE} alt=""/> : <img src={LOCK_FALSE} alt=""/>}
    </label>
  </div>
)}


const TemplateSelector = () => {
  const {channel, visible, locked} = useSetting();

  return (
    <div className="template-selector">
      {templates[channel.value].map(t => {
        return (
          <Item
            key={t.id}
            label={t.label}
            id={t.id}
            isVisible={visible.some(x => x === t.id)}
            isLocked={locked.some(x => x === t.id)}
          />
        )
      })}
    </div>
  )}

export default TemplateSelector;