import React, { useState, useEffect, useRef } from 'react';
import "./Textarea.css";
import cn from "classnames";
import {findParentWithClass, updateTextarea} from "../../libs/tools"
import {useSetting} from "../../contexts/setting";
import {useEdits} from "../../contexts/edits";
import {TEXT_CLASSNAMES} from "../../data/constants";

const Textarea = ({
  text,
  id,
}) => {
  const [ type, setType ]  = useState('');
  const { fontOption, locked } = useSetting();
  const { editSubtitles, editTitles, titles, subtitles } = useEdits();
  const labelRef = useRef(null);
  // let type;
  const isLocked = locked.some(elem => elem === id);
  const onTextChange = (e) => {
    switch (type) {
      case "title": {
        editTitles(e.target.value, id, isLocked);
        break;
      }
      case "subtitle": {
        editSubtitles(e.target.value, id, isLocked);
        break;
      }
    }

    labelRef.current.dataset.value = e.target.value;
  };


  const textareaClass = cn("textarea", `font-${fontOption}`);

  useEffect(() => {
    const t = findParentWithClass(labelRef.current, TEXT_CLASSNAMES);
    setType(t);
  }, []);


  useEffect(() => {
    labelRef.current.dataset.value = text;
  }, [text]);

  return (
    <div className="textarea-wrap">
      <label className="input-sizer" ref={labelRef}>
        <textarea
          className={textareaClass}
          onChange={onTextChange}
          value={text}
        />
      </label>
    </div>
  );
};

export default Textarea;

