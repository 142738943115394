import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import ImageUploading from "react-images-uploading";
import './index.css';
import cn from "classnames";
import { useImages } from "../../contexts/images";
import {useEdits} from "../../contexts/edits";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import UndoIcon from "../UndoIcon";
import TrashBin from "../TrashBin";
import {logoIDE} from "../../data/constants";



const ImageUpload = ({
  className,
  id,
  onPositionChange,
  minScale,
  initialScale
}) => {

  const [orientation, setOrientation] = useState("horiz");

  const classSizes = {
    [orientation === "horiz" ? "height" : "width"]: "100%",
  };
  const setTransformRef = useRef(null);
  const {images, editImages, removeImages, uploadX, uploadY, uploadScale, editUploadY, editUploadX, editUploadScale, renderKey } = useImages();


  useEffect(() => {
  // console.log('image uploaded', images, images.main)
}, [images])

  // set background position when loading a saved scene
  useEffect(() => {
    if (setTransformRef.current) {
      setTransformRef.current(uploadX[id], uploadY[id], uploadScale[id]);
    }
  }, [renderKey]);


  const onImageRemove = () => {
    editImages({})
  }

  const handleTransform = value => {
    editUploadScale(id, value.state.scale);
    editUploadX(id, value.state.positionX);
    editUploadY(id, value.state.positionY);
    // console.log("modified transform:", id, value.state)
  }

  return (

    <div className="image-upload-outer">

      <ImageUploading
        value={images.main}
        onChange={imgList => editImages(imgList[0].data_url, id)}
        maxNumber={1}
        dataURLKey="data_url"
      >

      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (

        <div className={cn("image-upload", className)} >


          {!images.main && (
            <button
              className="dropzone"
              onClick={onImageUpload}
              {...dragProps}
            >
              Click or Drop
              <br/>
              Image here
            </button>
          )}



          {images.main && (
            <div className="picture">

              <TransformWrapper
                initialScale={1}
                minScale={0.2}
                // initialPositionX={200}
                // initialPositionY={100}
                onTransformed={handleTransform}
                limitToBounds={false}
                centerZoomedOut={false}
                centerOnInit={true}
                wheel={{ step: 0.03 }}
              >
                {({ zoomIn, zoomOut, resetTransform, setTransform, ...rest }) => {
                  setTransformRef.current = setTransform;

                  return (
                  <>

                    <TransformComponent
                      wrapperClass="image-wrapper"
                    >
                      <img src={images.main} alt="uploaded artwork" />
                    </TransformComponent>
                    <div className="tools">
                      <button className="round-btn" onClick={() => zoomIn()}>+</button>
                      <button className="round-btn"  onClick={() => zoomOut()}>−</button>
                      <button className="round-btn"  onClick={() => resetTransform()}>
                        <UndoIcon/>
                      </button>
                      <button
                        className="round-btn"
                        onClick={() => {
                          removeImages();
                          onImageRemove();
                        }}
                      >
                        <TrashBin/>
                      </button>
                    </div>
                  </>
                  )}}
              </TransformWrapper>
            </div>
          )}


        </div>
      )}
    </ImageUploading>


    </div>
  )
}

export default ImageUpload;