import React, {useEffect} from "react";
import {useSetting} from "../../contexts/setting";
import {useEdits} from "../../contexts/edits";
import cn from "classnames";
import './index.css';
import { PLACEHOLDERS } from "../../data/constants";



const Button = ({value, handleClick, isCurrent}) => {
  const btnClass = cn('font-button', { "selected": isCurrent });
  return (
    <button
      value={value}
      className={btnClass}
      onClick={() => handleClick(value)}
    >{value.toUpperCase()}</button>
  )
}


const FontSelector = () => {
  const {fontOption, editFontOption } = useSetting();
  const {editTitles, editSubtitles } = useEdits();
  const labels = Object.keys(PLACEHOLDERS);

  useEffect( () => {
    editSubtitles(PLACEHOLDERS[fontOption].subtitle);
    editTitles(PLACEHOLDERS[fontOption].title);
  }, [fontOption])



  const onBgButtonClick = value => {
    editFontOption(value);
  }


  return (
    <div className="font-selector">
      {labels.map(x => (
        <Button
          key={x}
          value={x}
          handleClick={onBgButtonClick}
          isCurrent={fontOption === x}
        />
      ))}
    </div>
  )}

export default FontSelector;