import React from "react";
import "./index.css";
import cn from "classnames";
import Textarea from "../../../layout/Textarea/Textarea";
import ImageUpload from "../../../layout/ImageUpload/index";
import { logoNorm, logoIDE, UI} from "../../../data/constants";
import { checkIfIDE } from "../../../libs/tools";
import Icon from "../../../layout/Icon";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import {useImages} from "../../../contexts/images";

const BlogSocialShare = ({ id  }) => {
  const { product, layoutOption, iconOption, locked, visible } = useSetting();
  const { bgUrl2x } = useImages();
  const { titles, subtitles } = useEdits();
  const logoURL = checkIfIDE(product.value) ? logoIDE : logoNorm;
  const title = titles[id] || titles.main;
  const subtitle = subtitles[id] || subtitles.main;
  const iconUrl = `${UI}/Icons/${iconOption}.svg`
  const templateClass = cn("template", `layout-${layoutOption}`);
  const contentClass = cn("content", `layout-${layoutOption}`);
  const uploadClass = cn("upload-wrap", `layout-${layoutOption}`);
  const logoClass = cn("logo", {"ide": checkIfIDE(product.value)});
  const isLocked = locked.some(elem => elem === id);
  const isVisible = visible.some(elem => elem === id);

  return (
    <>

      <div id={id} className={templateClass}>
        <div>
          <BgTransformer src={bgUrl2x} id={id} />

          <div className="content" >
            <header className="template-header">
              <div className={logoClass}>
                <img src={logoURL} alt="background"/>
              </div>
              { product.value === "JB" ? "" : (
                <div className="product-name">
                  {product.label}
                </div>
              )}
            </header>

            <div className="title-wrap">
              <h1 className="title">
                <Textarea id={id} text={title}/>
              </h1>
              {[3, 4].includes(layoutOption) && (
                <h2 className="subtitle">
                  <Textarea id={id} lines="1" text={subtitle}/>
                </h2>
              )}
            </div>
          </div>
          {layoutOption === 7 && (<div className="icon-wrap">
            <Icon bgUrl={iconUrl}/>
          </div>)}
          {[5, 6].includes(layoutOption) && (<div className="upload-wrap">
            <ImageUpload id={id}/>
          </div>)}
        </div>
      </div>
    </>
  );
}

export default BlogSocialShare;