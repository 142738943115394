import React, {useEffect} from "react";
import {useImages} from "../../contexts/images";
import {useSetting} from "../../contexts/setting";
import cn from "classnames";
import './index.css';
import {BACKGROUNDS} from "../../data/constants";
import {DEFAULT_BACKGROUND_DATA} from "../../data/backgrounds";
import {API} from "../../api";


const Button = ({value, handleClick, src, isCurrent}) => {
  const btnClass = cn('bg-button', { "selected": isCurrent });
  return (
    <button
      value={value}
      className={btnClass}
      onClick={() => handleClick(value)}
      style={{backgroundImage: `url(${src})`}}
    ></button>
  )
}

const UploadButton = ({handleClick, isCurrent}) => {
  const btnClass = cn('bg-button upload-button');
  return (
    <div>
      <input
        type="file"
        accept="image/*"
        onChange={(e) => handleClick(e, "custom")}
        style={{ display: 'none' }}
        id="upload-button"
      />
      <label
        htmlFor="upload-button"
        className={btnClass}
      >
          <span>Upload File</span>
      </label>
    </div>
  )
}

const BgSelector = ({ amount }) => {
  const {bgOption, editBgOption, type, channel, product } = useSetting()
  const { editTransform, rerenderBg, editCustomBg, bgHashes, bgData } = useImages();
  const getSrc = (c, t, index, p) =>  (`${BACKGROUNDS}/${c}/${t}/${index}/${p}.png`);


  useEffect( () => {
    transformBg();
  }, [])

  const transformBg = (option = bgOption) => {
    const { x, y, scale } = bgData[option] || DEFAULT_BACKGROUND_DATA ;
    editTransform(x, y, scale);
    rerenderBg();
  }

  const onBgButtonClick = value => {
    editBgOption(value)
    transformBg(value)
    editCustomBg("")
  }
  const onBgUploadClick = (event, value) => {
    editBgOption(value)
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('res',reader.result )
        editCustomBg(reader.result)
      };
      reader.readAsDataURL(file);
    }
    transformBg(value)
  }


  return (
    <div className="bg-selector">
      {[...new Array(amount)].map((x, i) => {
        return (
          <Button
            key={i + 1}
            value={i + 1}
            src={getSrc(channel.value, type.value, i + 1, product.value)}
            handleClick={onBgButtonClick}
            isCurrent={+bgOption === i + 1}
          />
        )
      })}

      <UploadButton
        key="custom"
        value="custom"
        handleClick={onBgUploadClick}
        isCurrent={bgOption === "custom"}
      />

    </div>
  )}

export default BgSelector;